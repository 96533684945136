'use strict';

const buttonBell = document.querySelector('#bell'),
      buttonInvite = document.querySelector('#invite'),
      bodyElement = document.querySelector('body');

function enableDarkTheme() {
  if(bodyElement.classList.contains('theme-dark')) {
    bodyElement.classList.remove('theme-dark')
  } else {
    bodyElement.classList.add('theme-dark');
  }
}

function enablePartyMode() {
  enableDarkTheme();

  bodyElement.classList.add('mode-party');
}

buttonBell.addEventListener('click', function() {
  enableDarkTheme();
});

buttonInvite.addEventListener('click', function() {
  enablePartyMode();
});
